import React from "react";
import CalendarInput from "./CalendarInput";
import BookingStepsHeading from "./BookingStepsHeading";

const NeedUsToClean = ({
	setFormData,
	formData,
	handleButtonSelect,
	stepsnum,
}) => {
	return (
		<>
			<div className="w-full p-4 flex flex-col sm:gap-6 gap-10">
				<div className="flex flex-col gap-1">
					<BookingStepsHeading
						stepsHeading="How frequently do you need us to clean?"
						stepsnum={stepsnum}
					/>
				</div>
				<div className="step2 contact-form flex flex-row md:gap-4 gap-3 flex-wrap sm:w-full">
					<button
						onClick={() => handleButtonSelect(1)}
						className={`form-field md:w-[238px] sm:w-[164px] h-[79px] rounded-xl px-4 py-2 mb-2 ${
							formData.property === 1 ? "selected" : ""
						}`}
					>
						Once off
					</button>
					<div className="relative">
						<div className="badge inter-600 text-[8px] text-center leading-[10px] text-white">
							MOST POPULAR
						</div>
						<button
							onClick={() => handleButtonSelect(2)}
							className={`form-field popular-button md:w-[238px] sm:w-[164px] h-[79px] rounded-xl px-4 py-2 mb-2 ${
								formData.property === 2 ? "selected" : ""
							}`}
						>
							Weekly
						</button>
					</div>
					<button
						onClick={() => handleButtonSelect(3)}
						className={`form-field sm:w-[164px] md:w-[238px] h-[79px] rounded-xl border border-blue-400 px-4 py-2 mb-2 ${
							formData.property === 3 ? "selected" : ""
						}`}
					>
						Fortnightly
					</button>
					<button
						onClick={() => handleButtonSelect(4)}
						className={`form-field h-[79px] sm:w-[164px] md:w-[238px] rounded-xl border border-blue-400 px-4 py-2 mb-2 ${
							formData.property === 4 ? "selected" : ""
						}`}
					>
						Monthly
					</button>
				</div>
				<div className="w-full flex flex-row sm:flex-col gap-4">
					<CalendarInput setFormData={setFormData} formData={formData} />
				</div>
			</div>
		</>
	);
};

export default NeedUsToClean;

import React from "react";
import { Link } from "react-router-dom";

const NotFoundPage = () => {
	return (
		<div className="mx-auto text-center mt-8">
			<h1 className="text-5xl mb-5">404 - Page Not Found</h1>
			<p className="text-xl mb-2">The page you are looking for does not exist.</p>
			<Link to="/" className="underline">Go back home</Link>
		</div>
	);
};

export default NotFoundPage;

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<div className="sm:max-w-[767px] md:w-[1440px] flex flex-col sm:gap-4 mx-auto">
		<App />
	</div>
);

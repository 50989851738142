import React from "react";
import FbIcon from "../../../assets/fb.png";
import WtspIcon from "../../../assets/wtsp.png";
import { Link } from "react-router-dom";

const ConnectWithUsComp = ({ textColor }) => {
	return (
		<>
			<div className="flex absolute right-0 gap-4 rounded-s-lg connectwithus bg-white bg-opacity-20 px-8 py-3">
				<p
					className={`inter-medium text-sm text-center  ${textColor} line-height-22 relative`}
				>
					Connect with us
				</p>
				<div className="flex gap-4 h-6 items-center opacity-100 z-40 blue">
					<div className="w-6 h-6 custom-blue-bg bg-opacity-100  rounded-md relative opacity-100">
						<Link to="https://www.facebook.com/homeglitzwa">
							<img
								className="w-4 h-4 absolute top-1 left-1"
								src={FbIcon}
								alt="fbicon"
							/>
						</Link>
					</div>
					<div className="w-6 h-6 custom-blue-bg rounded-md relative">
						<Link to="https://wa.me/61474769147">
							<img
								className="w-4 h-4 absolute top-1 left-1"
								src={WtspIcon}
								alt="wtspicon"
							/>
						</Link>
					</div>
				</div>
			</div>
		</>
	);
};

export default ConnectWithUsComp;

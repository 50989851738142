import React, { useState } from "react";
import BookingCheckList from "./BookingCheckList";
import BookingSlider from "./BookingSlider";
import BookingSummary from "./BookingSummary";
import BookingStepsHeading from "./BookingStepsHeading";
import { toast } from "react-toastify";
import NeedUsToClean from "./NeedUsToClean";
import BookingContactUs from "../BookingComponents/BookingContactUs";
import BookingErrorsToast from "./BookingStep3Error";
import PaymentMethod from "./PaymentMethod";
import BookingSuccess from "./BookingSuccess";
import CreditDebitForm from "../../components/BookingComponents/CreditDebitForm";
import Step4Error from "./Step4Error";
import BookingNav from "./BookingNav";
import BookingCounter from "./BookingCounter";
import BookingNextPrevBtns from "./BookingNextPrevBtn";
import dayjs from "dayjs";

const BookingStepsComponent = () => {
	const [currentStep, setCurrentStep] = useState(1);
	const [stepsnum, setStepsnum] = useState(1);
	const [formData, setFormData] = useState({
		step1Data: {
			counter1: 1,
			counter2: 1,
			counter3: 1,
			totalPrice: 120,
		},
		step2Data: {
			property: null,
			newDate: new Date(),
			time: dayjs(),
			dateFormatted: new Date().toDateString(),
			timeFormatted: dayjs().format("LT"),
		},
		step3Data: {
			name: "",
			email: "",
			phone: "",
			address: "",
			suburb: "",
			state: "",
			postcode: "",
		},
		step4Data: {
			paymentMethod: null,
			cardNumber: "",
			expiryDate: "",
			cvc: "",
			termsandcondiotions: false,
			understandcondiotions: false,
		},
	});

	const [step3Error, setStep3Error] = useState({
		name: "",
		email: "",
		phone: "",
		address: "",
		suburb: "",
		state: "",
		postcode: "",
		isValid: true,
	});
	const [step4Error, setStep4Error] = useState({
		cardNumber: "",
		expiryDate: "",
		cvc: "",
		termsandcondiotions: "",
		understandcondiotions: "",
	});

	const [stepsCompleted, setStepsCompleted] = useState({
		step1: true,
		step2: false,
		step3: false,
		step4: false,
		step5: false,
		step6: false,
		submitted: false,
	});

	// begin: states for airwallex apis
	const [stateAirWallexObj, setStateAirWallexObj] = useState({
		customerId: null,
		paymentIntentId: null,
		apiInProgress: "",
	});

	const setStateAirWallex = (propName = null, propVal = null) => {
		setStateAirWallexObj((prev) => ({
			...prev,
			[propName]: propVal,
		}));
	};
	// end: states for airwallex apis

	const validateCreditCardForm = () => {
		let step4ErrorsObj = {
			cardNumber: "",
			expiryDate: "",
			cvc: "",
			termsandcondiotions: "",
			understandcondiotions: "",
			isValid: true,
		};
		if (!formData.step4Data.cardNumber) {
			step4ErrorsObj.cardNumber =
				"Please enter your card number to proceed with the transaction!";
			step4ErrorsObj.isValid = false;
		} else if (!/^(?:\d[ -]*?){13,16}$/.test(formData.step4Data.cardNumber)) {
			step4ErrorsObj.cardNumber = "Card number provided is invalid!";
			step4ErrorsObj.isValid = false;
		}

		// Expiry date validation: must be in MM/YY format
		if (!formData.step4Data.expiryDate) {
			step4ErrorsObj.expiryDate =
				"Please enter a valid expiry date for your card to continue!";
			step4ErrorsObj.isValid = false;
		}

		// CVC validation: must be 3 or 4 digits
		if (!formData.step4Data.cvc) {
			step4ErrorsObj.cvc =
				"Please provide the CVC code to complete your payment!";
			step4ErrorsObj.isValid = false;
		} else if (/^[0-9]{3 4}$/.test(formData.step4Data.cvc)) {
			step4ErrorsObj.cvc = "Invalid CVC. Must be 3 or 4 digits.";
			step4ErrorsObj.isValid = false;
		}

		//checkbox validation:must be checked bothe checkbox
		if (!formData.step4Data.termsandcondiotions) {
			step4ErrorsObj.termsandcondiotions =
				"You must agree to our terms and conditions before proceeding!";
			step4ErrorsObj.isValid = false;
		}

		if (!formData.step4Data.understandcondiotions) {
			step4ErrorsObj.understandcondiotions =
				"You must agree to our terms and conditions before proceeding!";
			step4ErrorsObj.isValid = false;
		}

		setStep4Error(step4ErrorsObj);
		return step4ErrorsObj.isValid;
	};

	const contactFormValidateStep3 = () => {
		let step3ErrorsObj = {
			name: "",
			email: "",
			phone: "",
			address: "",
			suburb: "",
			state: "",
			postcode: "",
			isValid: true,
		};

		if (!formData.step3Data.name) {
			step3ErrorsObj.name = "Provide valid value for Name";
			step3ErrorsObj.isValid = false;
		}

		if (!formData.step3Data.email) {
			step3ErrorsObj.email = "Provide valid value for Email";
			step3ErrorsObj.isValid = false;
		} else if (!/\S+@\S+\.\S+/.test(formData.step3Data.email)) {
			step3ErrorsObj.email = "Email is invalid";
			step3ErrorsObj.isValid = false;
		}

		if (!formData.step3Data.phone) {
			step3ErrorsObj.phone = "Provide valid value for Phone";
			step3ErrorsObj.isValid = false;
		} else if (!/^(\+\d{1,3}[- ]?)?\d{10}$/.test(formData.step3Data.phone)) {
			step3ErrorsObj.phone = "Phone number is invalid";
			step3ErrorsObj.isValid = false;
		}

		if (!formData.step3Data.address) {
			step3ErrorsObj.address = "Provide valid value for Address";
			step3ErrorsObj.isValid = false;
		}

		if (!formData.step3Data.suburb) {
			step3ErrorsObj.suburb = "Provide valid value for Suburb";
			step3ErrorsObj.isValid = false;
		}

		if (!formData.step3Data.state) {
			step3ErrorsObj.state = "Provide valid Select State";
			step3ErrorsObj.isValid = false;
		}

		if (!formData.step3Data.postcode) {
			step3ErrorsObj.postcode = "Provide valid value for Postcode";
			step3ErrorsObj.isValid = false;
		} else if (!/^\d{4}$/.test(formData.step3Data.postcode)) {
			step3ErrorsObj.postcode = "Postcode is invalid";
			step3ErrorsObj.isValid = false;
		}
		setStep3Error(step3ErrorsObj);
		return step3ErrorsObj.isValid;
	};

	const executeApi = async (endPoint, apiMethod = "GET", formData = null) => {
		try {
			setStateAirWallex("apiInProgress", "loading...");
			let fetchPayload = {};
			fetchPayload.method = apiMethod;
			if (formData) {
				fetchPayload.body = JSON.stringify(formData);
				fetchPayload.headers = {
					"Content-type": "application/json; charset=UTF-8",
				};
			}

			const response = await fetch(
				"https://api.homeglitz.com.au" + endPoint,
				fetchPayload
			);

			const responseJson = await response.json();
			if (response?.ok) {
				if (responseJson.message) toast.success(responseJson.message);
				setStateAirWallex("apiInProgress", "");
			} else {
				toast.error(responseJson.message);
				setStateAirWallex("apiInProgress", "");
			}

			return responseJson;
		} catch (error) {
			toast.error(error);
			setStateAirWallex("apiInProgress", "");
		}
	};

	const handleNext = async () => {
		const isValidStep1 = Object.values(formData.step1Data).every(
			(count) => count > 0
		);
		const isValidStep2 =
			formData.step2Data.property &&
			formData.step2Data.newDate &&
			formData.step2Data.time !== null;
		const isValidStep3 = contactFormValidateStep3();
		const isValidStep5 = validateCreditCardForm();
		if (currentStep === 1) {
			if (isValidStep1) {
				setStepsCompleted((prev) => ({ ...prev, step1: true }));
				setCurrentStep(currentStep + 1);
				setStepsnum(stepsnum + 1);
				// setStateAirWallex("accessToken", accessToken);
			} else {
				let step1Errobj = [];
				if (!formData.step1Data.counter1) {
					step1Errobj.push("Living areas must be 1 or below 6!");
				}
				if (!formData.step1Data.counter2) {
					step1Errobj.push("Bath areas must be 1 or below 5!");
				}
				if (!formData.step1Data.counter3) {
					step1Errobj.push("Bedrooms areas must be 1 or below 7!");
				}
				toast.error(step1Errobj.join("\n"));
			}
		} else if (currentStep === 2) {
			if (isValidStep2) {
				setStepsCompleted((prev) => ({ ...prev, step2: true }));
				setCurrentStep(currentStep + 1);
				setStepsnum(stepsnum + 1);
			} else {
				let step2Errobj = [];
				if (!formData.step2Data.property) {
					step2Errobj.push("Select cleaning frequency!");
				}
				if (!formData.step2Data.newDate) {
					step2Errobj.push("Select start date!");
				}
				if (!formData.step2Data.time) {
					step2Errobj.push("Select start time!");
				}
				toast.error(step2Errobj.join("\n"));
			}
		} else if (currentStep === 3) {
			if (isValidStep3) {
				setStepsCompleted((prev) => ({ ...prev, step3: true }));
				setCurrentStep(currentStep + 1);
				setStepsnum(stepsnum + 1);
			} else {
				toast.error(<BookingErrorsToast errors={step3Error} />);
			}
		}
		if (currentStep === 4) {
			if (formData.step4Data.paymentMethod === "Credit Card") {
				setCurrentStep(5);
			} else if (formData.step4Data.paymentMethod === "Bank transfer") {
				let completeBookingResponse = await executeApi(
					"/complete_booking/send",
					"POST",
					formData
				);
				if (!completeBookingResponse.error) {
					setStepsCompleted((prev) => ({ ...prev, step6: true }));
					setStepsnum(6);
					setCurrentStep(6);
				}
			} else {
				toast.error("Select a payment method!");
			}
		} else if (currentStep === 5) {
			if (isValidStep5) {
				let completeBookingResponse = await executeApi(
					"/complete_booking/send",
					"POST",
					formData
				);
				if (!completeBookingResponse.error) {
					setStepsCompleted((prev) => ({ ...prev, step6: true }));
					setStepsnum(6);
					setCurrentStep(6);
				}
			} else {
				toast.error(<Step4Error errors={step4Error} />);
			}
		}
	};

	const handlePrevious = () => {
		if (currentStep === 2) {
			setStepsCompleted((prev) => ({
				...prev,
				step1: false,
				step2: false,
			}));
		} else if (currentStep === 3) {
			setStepsCompleted((prev) => ({
				...prev,
				step2: false,
				step3: false,
			}));
		}
		if (currentStep === 4) {
			setStepsCompleted((prev) => ({
				...prev,
				step3: false,
				step4: false,
			}));
		} else if (currentStep === 5) {
			setStepsCompleted((prev) => ({
				...prev,
				step4: false,
				step5: false,
			}));
		}

		currentStep <= 4 ? setStepsnum(stepsnum - 1) : setStepsnum(4);
		setCurrentStep(currentStep - 1);
	};

	const handleButtonSelect = (button) => {
		setFormData((prev) => ({
			...prev,
			step2Data: {
				...prev.step2Data,
				property: button,
			},
		}));
	};

	const handleStepComplete = (step) => {
		setStepsCompleted((prev) => ({
			...prev,
			[step]: !prev[step],
		}));
	};

	return (
		<>
			<div className="md:max-w-[1440px] sm:w-full mx-auto flex justify-between items-center">
				<BookingNav
					totalBookingPrice={formData.step1Data}
					currentStep={currentStep}
				/>
			</div>
			<div className="md:px-8">
				<BookingSlider currentStep={currentStep} />
			</div>
			{currentStep < 6 && (
				<div className="flex gap-[90px] justify-center md:w-[1440px] md:mt-[42px] md:px-8">
					<BookingCheckList
						currentStep={currentStep}
						stepsCompleted={stepsCompleted}
						onStepComplete={handleStepComplete}
					/>
					<div className="w-full md:w-[524px] flex flex-col sm:gap-4 gap-6">
						{currentStep === 1 && (
							<div className="w-full p-4 flex flex-col sm:gap-6 gap-10">
								<div className="flex flex-col gap-1">
									<BookingStepsHeading
										stepsHeading="First tell us about your property:"
										stepsnum={stepsnum}
									/>
								</div>
								<div className="step1 flex flex-col gap-6">
									<BookingCounter
										setFormData={setFormData}
										fieldValue={formData.step1Data.counter1}
										counterKey="counter1"
										counterHeading="Number of living areas"
										maxValue={5}
									/>
									<BookingCounter
										setFormData={setFormData}
										fieldValue={formData.step1Data.counter2}
										counterKey="counter2"
										counterHeading="Number of bathrooms"
										maxValue={4}
									/>
									<BookingCounter
										fieldValue={formData.step1Data.counter3}
										setFormData={setFormData}
										counterKey="counter3"
										counterHeading="Number of bedrooms"
										maxValue={6}
									/>
								</div>
							</div>
						)}
						{currentStep === 2 && (
							<NeedUsToClean
								handleButtonSelect={handleButtonSelect}
								setFormData={setFormData}
								formData={formData.step2Data}
								stepsnum={stepsnum}
							/>
						)}
						{currentStep === 3 && (
							<BookingContactUs
								stepsnum={stepsnum}
								formData={formData.step3Data}
								setFormData={setFormData}
								step3Error={step3Error}
								setStep3Error={setStep3Error}
								contactFormValidateStep3={contactFormValidateStep3}
							/>
						)}
						{currentStep === 4 && (
							<PaymentMethod
								stepsnum={stepsnum}
								formData={formData.step4Data}
								setFormData={setFormData}
							/>
						)}
						{currentStep === 5 &&
							formData.step4Data.paymentMethod === "Credit Card" && (
								<CreditDebitForm
									stepsnum={stepsnum}
									formData={formData.step4Data}
									setFormData={setFormData}
								/>
							)}
						<BookingNextPrevBtns
							currentStep={currentStep}
							handleNext={handleNext}
							handlePrevious={handlePrevious}
							stateAirWallexObj={stateAirWallexObj.apiInProgress}
						/>
					</div>
					<BookingSummary
						currentStep={currentStep}
						totalBookingPrice={formData.step1Data}
						bookingDateAndTime={formData.step2Data}
						contactDetails={formData.step3Data}
					/>
				</div>
			)}
			{currentStep === 6 && (
				<BookingSuccess customerName={formData.step3Data.name} />
			)}
		</>
	);
};
export default BookingStepsComponent;

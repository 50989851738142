import React from "react";

const BookingStepsHeading = ({ stepsHeading, stepsnum, payMethodPara }) => {
	return (
		<>
			<span className="inter-normal text-sm line-height-22 custom-gray06">
				Steps {stepsnum}/4
			</span>
			<h1 className="inter-600 sm:text-[22px] text-[28px] leading-8 custom-gray09">
				{stepsHeading}
			</h1>
			<p className="inter-normal text-sm line-height-22 custom-gray06">
				{payMethodPara}
			</p>
		</>
	);
};

export default BookingStepsHeading;

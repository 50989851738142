import React from 'react'
import Airbnd from '../components/AirBnd/Airbnd'

const AirBnbPage = () => {
	return (
		<div>
			<Airbnd />
		</div>
	)
}

export default AirBnbPage
import React from "react";
import visaLogo from "../../assets/Visa.png";
import mastercardLogo from "../../assets/creditcard-mastercard.png";
import amexLogo from "../../assets/americanexp.png";
import { useState } from "react";
import BookingStepsHeading from "./BookingStepsHeading";

const CreditDebitForm = ({ formData, setFormData, stepsnum }) => {
	const [cardType, setCardType] = useState("");

	const onChangeEvent = (e) => {
		const { name, checked } = e.target;
		setFormData((prev) => ({
			...prev,
			step4Data: {
				...prev.step4Data,
				[name]: checked,
			},
		}));
	};

	const handleInputChange = (e) => {
		const { name, value } = e.target;

		if (name === "cardNumber") {
			let detectedCardType = "";

			if (/^4/.test(value)) {
				detectedCardType = "visa";
			} else if (/^5[1-5]/.test(value)) {
				detectedCardType = "mastercard";
			} else if (/^3[47]/.test(value)) {
				detectedCardType = "amex";
			} else {
				detectedCardType = "";
			}

			setCardType(detectedCardType);
		}

		if (name === "expiryDate") {
			e.target.value = e.target.value
				.replace(
					/^([1-9]\/|[2-9])$/g,
					"0$1/" // 3 > 03/
				)
				.replace(
					/^(0[1-9]|1[0-2])$/g,
					"$1/" // 11 > 11/
				)
				.replace(
					/^([0-1])([3-9])$/g,
					"0$1/$2" // 13 > 01/3
				)
				.replace(
					/^(0?[1-9]|1[0-2])([0-9]{2})$/g,
					"$1/$2" // 141 > 01/41
				)
				.replace(
					/^([0]+)\/|[0]+$/g,
					"0" // 0/ > 0 and 00 > 0
				)
				.replace(
					/[^\d\/]|^[\/]*$/g,
					"" // To allow only digits and `/`
				)
				.replace(
					/\/\//g,
					"/" // Prevent entering more than 1 `/`
				);
			console.log(e.target.value);
		}

		setFormData((prev) => ({
			...prev,
			step4Data: {
				...prev.step4Data,
				[name]: value,
				[name]: e.target.value,
			},
		}));
	};

	return (
		<>
			<div className="w-full p-4 flex flex-col sm:gap-6 gap-10">
				<div className="flex flex-col gap-1">
					<BookingStepsHeading
						stepsHeading="Complete booking"
						stepsnum={stepsnum && 4}
						payMethodPara={
							"Please enter your card details below to complete the booking"
						}
					/>
				</div>

				<div className="credit-card-form flex flex-col gap-6">
					<div className="flex flex-col gap-1">
						<label
							htmlFor="expiryDate"
							className="custom-gray08 inter-medium text-sm line-height-22"
						>
							Card Number*
						</label>
						<div className="contact-form flex flex-row justify-center items-center gap-4 relative">
							<div className="flex justify-center items-center form-field custom-gray06 custom-blue02 line-height-22 text-sm inter-normal form-field focus:outline-none rounded-md h-12 px-3 py-2 w-full">
								<input
									type="text"
									id="cardNumber"
									name="cardNumber"
									pattern="/d*"
									maxLength={19}
									placeholder="1234 1234 1234 1234"
									value={formData.cardNumber || ""}
									onChange={handleInputChange}
									className="remove-arrow  w-full focus:outline-none flex justify-center items-center"
								/>
								<div className="flex flex-row gap-2 absolute right-3 justify-end items-center">
									<img
										src={visaLogo}
										alt="visa"
										className={` ${
											cardType === "visa" || cardType === ""
												? "block"
												: "hidden"
										}`}
									/>
									<img
										src={mastercardLogo}
										alt="masterCard"
										className={` ${
											cardType === "mastercard" || cardType === ""
												? "block"
												: "hidden"
										}`}
									/>
									<img
										src={amexLogo}
										alt="amex"
										className={` w-4 object-cover ${
											cardType === "amex" || cardType === ""
												? "block"
												: "hidden"
										}`}
									/>
								</div>
							</div>
						</div>
					</div>
					<div className="contact-form flex flex-row gap-6">
						<div className="flex flex-col gap-1 w-1/2">
							<label
								htmlFor="expiryDate"
								className="custom-gray08 inter-medium text-sm line-height-22"
							>
								Expiry Date (MM / YY)*
							</label>
							<input
								type="text"
								id="expiryDate"
								name="expiryDate"
								placeholder="MM/YY"
								maxLength={5}
								value={formData.expiryDate || ""}
								onChange={handleInputChange}
								className="custom-gray06 custom-blue02 line-height-22 text-sm inter-normal form-field focus:outline-none rounded-md h-12 px-3 py-2"
							/>
						</div>
						<div className="flex flex-col gap-1 w-1/2">
							<label
								htmlFor="cvc"
								className="custom-gray08 inter-medium text-sm line-height-22"
							>
								CVC*
							</label>
							<input
								type="text"
								id="cvc"
								name="cvc"
								maxLength={4}
								pattern="\d*"
								placeholder="CVC"
								value={formData.cvc || ""}
								onChange={handleInputChange}
								className="remove-arrow custom-gray06 custom-blue02 line-height-22 text-sm inter-normal form-field focus:outline-none rounded-md h-12 px-3 py-2"
							/>
						</div>
					</div>
					<div className="flex flex-col gap-6">
						<div className="flex gap-1 rounded-sm">
							<input
								checked={formData.termsandcondiotions}
								id="termsandcondiotions"
								name="termsandcondiotions"
								type="checkbox"
								value=""
								onChange={onChangeEvent}
								className="h-6 text-blue-600 bg-gray-100 border-gray-300 rounded  p-4 flex gap-2.5"
							/>

							<label
								htmlFor="default-checkbox"
								className="line-height-22 inter-normal text-sm custom-gray08"
							>
								By completing this booking, I confirm that the property
								specifications I have provided are true and accurate.
							</label>
						</div>

						<div className="flex gap-1 rounded-sm">
							<input
								id="understandcondiotions"
								checked={formData.understandcondiotions}
								name="understandcondiotions"
								type="checkbox"
								onChange={onChangeEvent}
								value=""
								className=" h-6 text-blue-600 bg-gray-100 border-gray-300 rounded  p-4 flex gap-2.5"
							/>
							<label
								htmlFor="default-checkbox"
								className="line-height-22 inter-normal text-sm custom-gray08"
							>
								I understand and agree that this booking is subject to the terms
								and conditions set by HomeGlitz and that it includes the
								standard inclusions of the cleaning service as outlined.
							</label>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default CreditDebitForm;

import React, { useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import calender from "../../assets/calendar-empty-alt.png";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { StaticTimePicker } from "@mui/x-date-pickers/StaticTimePicker";
import dayjs from "dayjs";
const weekdayOrder = ["S", "M", "T", "W", "T", "F", "S"];

const CalendarInput = ({ setFormData, formData }) => {
	const [showCalendar, setShowCalendar] = useState(false);
	const [showClock, setShowClock] = useState(false);

	const currentDate = new Date();
	const handleDateTimeChange = (newDate, newTime) => {
		setFormData((prev) => ({
			...prev,
			step2Data: {
				...prev.step2Data,
				date: newDate,
				time: newTime,
				dateFormatted: newDate.toDateString(),
				timeFormatted: newTime.format("LT"),
			},
		}));

		setShowCalendar(false);
		setShowClock(false);
	};

	const formatShortWeekday = (locale, date) => {
		const weekDays = weekdayOrder;
		const dayIndex = date.getDay();
		const shiftedDayIndex = (dayIndex + 6) % 7;
		return weekDays[shiftedDayIndex];
	};

	return (
		<>
			<div className="md:w-[95%] sm:w-full calendarClock-wrapper flex flex-col gap-1 h-12 px-2 py-2 items-center justify-center">
				<div className="flex w-full focus:outline-none flex-row gap-2 items-center justify-center">
					<img src={calender} alt="calender-icon" />
					<input
						type="text"
						value={
							formData.date
								? `${formData.dateFormatted}`
								: "When would you like the clean to start?"
						}
						onClick={() => setShowCalendar(!showCalendar)}
						readOnly
						placeholder="When would you like the clean to start?"
						className="inter-normal text focus:outline-none w-full custom-gray06 line-height-22"
					/>
				</div>

				{showCalendar && (
					<div className="calendar-container inter-normal flex flex-col">
						<Calendar
							onChange={(newDate) => {
								handleDateTimeChange(newDate, formData.time);
							}}
							value={formData.date || undefined}
							minDate={currentDate}
							formatShortWeekday={formatShortWeekday}
						/>
					</div>
				)}
			</div>
			<div className="sm:w-full calendarClock-wrapper flex flex-col gap-1 h-12 px-2 py-2 items-center justify-center">
				<div className="flex w-full focus:outline-none flex-row gap-2 items-center justify-center">
					<input
						type="text"
						value={`Time - ${formData.timeFormatted}`}
						onClick={() => setShowClock(!showClock)}
						readOnly
						placeholder="When would you like the clean to start?"
						className="inter-normal text focus:outline-none w-full custom-gray06 line-height-22"
					/>
				</div>
				{showClock && (
					<div className="inter-normal flex flex-col">
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<StaticTimePicker
								minTime={dayjs().set("hour", 8)}
								maxTime={dayjs().set("hour", 15).set("minutes", 59)}
								value={formData.time}
								onChange={(newTime) => {
									handleDateTimeChange(formData.newDate, newTime);
								}}
							/>
						</LocalizationProvider>
					</div>
				)}
			</div>
		</>
	);
};

export default CalendarInput;

import React from 'react'
import ConnectWithUsComp from '../HomeComp/HeroBanner/ConnectWithUsComp'
import { Link } from 'react-router-dom'
import airbnb from '../../assets/AIRBNB.jpg'
import ContactUs from '../ContactUsComp/ContactUs'
const Airbnd = () => {
	return (
		<>
			<img src={airbnb}
				alt="serviceImg"
				className="rounded-md h-full"
			/>
			<div className="mt-4 md:mt-[40px] flex flex-col gap-6 sm:p-4 md:w-[1040px] mx-auto">
				<h1 className="md:tracking-wide	sm:text-start text-center custom-gray08 inter-medium sm:text-2xl text-[32px] sm:leading-[29px] leading-[39px]">
					Airbnb
				</h1>
				<div className="flex flex-col gap-6">
					<div className=" flex flex-col gap-6">
						<span className="custom-gray08 inter-700 text-sm line-height-22">
						HomeGlitz - Your Trusted Partner in Airbnb Hosting Excellence
						</span>
						<p className="custom-gray08 inter-normal text-sm line-height-22">
							At HomeGlitz, we go beyond cleaning to offer exceptional <span className='font-bold'>Airbnb Co-Hosting</span> services tailored to elevate your hosting experience. Whether you’re an experienced host or new to the short-term rental market, our expert team ensures your property is managed efficiently while maximizing your revenue potential.
						</p>
						<p>---</p>
					</div>
					<div className="flex flex-col gap-6">
						<span className="custom-gray08 inter-700 text-sm line-height-22">
							Why Choose HomeGlitz for Co-Hosting?
						</span>
						<p className="custom-gray08 inter-normal text-sm line-height-22">
							We understand the unique challenges of hosting, and our dedicated approach ensures a stress-free experience. From <span className='font-bold'>guest communications</span> to <span className='font-bold'>property maintenance</span> property maintenance, we handle it all while keeping you in the loop. With commission charges ranging from just <span className='font-bold'>5-10%</span> or a negotiable <span className='font-bold'>flat monthly fee</span>, our services are both affordable and flexible.
						</p>
						<p>---</p>
					</div>
					<div className="flex flex-col gap-6">
						<span className="custom-gray08 inter-700 text-sm line-height-22">
							Our Co-Hosting Services Include:
						</span>
						<ul className="custom-gray08 inter-normal text-sm line-height-22 list-disc ml-9">
							<li><span className='font-bold'>Guest Communication</span>: Prompt, professional responses to inquiries, from booking confirmations to handling early check-ins, late check-outs and guest concerns.</li>
							<li><span className='font-bold'>Check-Ins and Check-Outs</span>: Seamless coordination to ensure every guest feels welcome from the moment they arrive.</li>
							<li><span className='font-bold'>Turnover Management</span>: We handle all <span className='font-bold'>changeover cleans, refreshing linen</span> and <span className='font-bold'>restocking essentials</span> to maintain your property’s five-star standard.</li>
							<li><span className='font-bold'>Reservation Management</span>: Optimize your booking calendar for maximum occupancy and profitability.</li>
							<li><span className='font-bold'>Dynamic Pricing</span>: Use tools like <span className='font-bold'>PriceLabs</span> to adjust pricing based on demand and market trends, ensuring you stay competitive.</li>
							<li><span className='font-bold'>Listing Optimization</span>: Enhance your Airbnb, Booking.com, Stayz and VRBO listings with professional descriptions, photography and competitive pricing strategies.</li>
							<li><span className='font-bold'>Property Maintenance</span>: Coordinate regular maintenance and housekeeping to ensure your property is always in top condition.</li>
						</ul>
						<p>---</p>
					</div>
					<div className="flex flex-col gap-6">
						<span className="custom-gray08 inter-700 text-sm line-height-22">
							The HomeGlitz Difference
						</span>
						<p className="custom-gray08 inter-normal text-sm line-height-22">
							Our team is proficient with Airbnb, Booking.com, Stayz and VRBO, ensuring your property stands out in a crowded market. With a customer-first approach, we navigate complex guest situations with ease and professionalism, leaving your guests impressed and your reviews glowing!
						</p>
						<p className="custom-gray08 inter-normal text-sm line-height-22 font-bold">
							Sit back, relax and let HomeGlitz handle the details while you enjoy the rewards of being a host!
						</p>
						<p>---</p>
					</div>
					<div className=" flex flex-col gap-6">
						<span className="custom-gray08 inter-700 text-sm line-height-22">
							Ready to Maximize Your Airbnb Potential?
						</span>
						<p className="custom-gray08 inter-normal text-sm line-height-22">
							Let us help you unlock your property’s earning potential with our hassle-free co-hosting services.
						</p>
						<p className="custom-gray08 inter-normal text-sm line-height-22">
							<span className='font-bold'>Contact us today</span> to discuss how we can customize our offerings to suit your needs.
						</p>
						<div className='flex flex-col'>
							<p><span className='font-bold'>📞 Phone</span>: 0474 769 147</p>
							<p><span className='font-bold'>📧 Email</span>:  <Link to="admin@homeglitz.com.au">admin@homeglitz.com.au</Link></p>
						</div>
					</div>
				</div>
			</div>
			<ContactUs pageTitle=""/>
			<div className="hidden md:block w-[100%] h-[100px] py-[10px] md:py-[26px]">
				<ConnectWithUsComp  textColor="text-black" />
			</div>
		</>
	)
}

export default Airbnd
import React from "react";
import CleaningServiceSection from "./CleaningServiceSection";
import serviceImgOne from "../../assets/fisrtserviceImg.jpg";
import serviceImgSecond from "../../assets/secondservice.jpg";
import serviceImgThird from "../../assets/thridserviceImg.jpg";

const serviceListItems = [
	{
		id: 1,
		heading: "Living Areas and Bedrooms",
		imgUrl: serviceImgOne,
		services: [
			"Dusting surfaces, furniture, and decor",
			"Vacuuming carpets and rugs",
			"Sweeping and mopping hard floors",
			"Cleaning mirrors and glass surfaces",
			"Emptying trash bins",
		],
	},
	{
		id: 2,
		heading: "Kitchen",
		imgUrl: serviceImgSecond,
		services: [
			"Wiping down countertops and surfaces",
			"Cleaning the exterior of appliances",
			"Cleaning the sink and faucet",
			"Wiping down cabinet doors",
			"Sweeping and mopping the floor",
			"Emptying trash bins",
		],
	},
	{
		id: 3,
		heading: "Bathrooms and Toilets",
		imgUrl: serviceImgThird,
		services: [
			"Cleaning and disinfecting the toilet, including the bowl, seat, and exterior",
			"Cleaning and disinfecting the shower, bathtub, and sink",
			"Wiping down countertops and surfaces",
			"Cleaning mirrors",
			"Sweeping and mopping the floor",
			"Emptying trash bins",
		],
	},
];

const CleaningServices = ({ cleaningServices, cleaningServicePara }) => {
	return (
		<>
			<div className="mx-auto w-full">
				<div className="flex flex-col justify-center items-center mx-auto gap-1  md:w-[792px] md:mt-[40px] sm:px-4 px-0">
					<h1 className="md:tracking-wide	sm:text-start text-center custom-gray08 inter-medium sm:text-2xl text-[32px] sm:leading-[29px] leading-[39px]">
						{cleaningServices}
					</h1>
					<p className="text-center md:block hidden inter-normal text-sm custom-gray06 leading-[17px]">
						{cleaningServicePara}
					</p>
				</div>
				<div className="md:w-[1069px] md:mt-[44px] mx-auto flex flex-col sm:gap-6 gap-10 sm:p-4 p-0 relative">
					<CleaningServiceSection serviceListItems={serviceListItems} />
				</div>
			</div>
		</>
	);
};

export default CleaningServices;

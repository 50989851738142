import React from "react";
const BookingCheckList = ({ currentStep, stepsCompleted, onStepComplete }) => {
	return (
		<div className="checkbox-container sm:hidden">
			<div className="flex gap-1 h-6 w-full">
				<input
					type="checkbox"
					value={stepsCompleted.step1}
					checked={currentStep === 1 || stepsCompleted.step1}
					onChange={() => onStepComplete("step1")}
					id="step1"
					className="sr-only w-full"
				/>
				<label
					htmlFor="toggle"
					className="flex gap-3 items-center cursor-pointer "
				>
					First tell us about your property.
				</label>
			</div>
			<div className="flex gap-1 h-6">
				<input
					type="checkbox"
					checked={currentStep === 2 || stepsCompleted.step2}
					onChange={() => onStepComplete("step1")}
					id="step2"
					className="sr-only"
				/>
				<label
					htmlFor="toggle"
					className="flex gap-3  items-center cursor-pointer "
				>
					How frequently do you need us to clean?
				</label>
			</div>
			<div className="flex  gap-1 h-6">
				<input
					type="checkbox"
					checked={currentStep === 3 || stepsCompleted.step3}
					onChange={() => onStepComplete("step1")}
					id="step3"
					className="sr-only"
				/>
				<label
					htmlFor="toggle"
					className="flex gap-3  items-center cursor-pointer "
				>
					Now, please share your contact details.
				</label>
			</div>
			<div className="flex  gap-1 h-6">
				<input
					type="checkbox"
					checked={currentStep === 4 || currentStep === 5 || currentStep === 6 || stepsCompleted.step4}
					onChange={() => onStepComplete("step1")}
					id="step3"
					className="sr-only"
				/>
				<label
					htmlFor="toggle"
					className="flex gap-3 items-center cursor-pointer"
				>
					Complete booking
				</label>
			</div>
		</div>
	);
};

export default BookingCheckList;

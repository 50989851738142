import React from "react";
import BookingAllStepsComp from "../components/BookingComponents/BookingStepsComponent";
import { ToastContainer } from "react-toastify";
const BookingPage = () => {
	return (
		<> 
				<BookingAllStepsComp />
				<ToastContainer />     
		</>
	);
};

export default BookingPage;

import React from "react";
import SuccessBooking from "../../assets/booking-successfully.png";

const BookingSuccess = ({customerName}) => {
	return (
		<div className="md:w-[796px] sm-w-full mx-auto md:text-center mt-5  sm:p-4  md:mt-20 flex flex-col gap-6 justify-center items-center">
			<img src={SuccessBooking} alt="successbooking" className="sm:w-[343px] sm:h-[86px]"/>
			<div className="md:w-[547px] flex flex-col gap-2">
				<h1 className="inter-600 text-[22px] leading-8 custom-gray09">
					Thank you {customerName}, your booking is now complete!
				</h1>
				<p className="inter-normal text-sm line-height-22 custom-gray06">
					Thank you for choosing HomeGlitz! Your booking has been successfully
					confirmed, and a confirmation email has been sent to you. If you need
					to make any changes to your booking or have any questions, feel free
					to reach out to us at{" "}
					<span className="custom-blue-text">0474769147</span> or
					email{" "}
					<span className="custom-blue-text">admin@homeglitz.com.au</span>.
					<br /> <br />
					We look forward to providing you with a sparkling clean home!
				</p>
			</div>
		</div>
	);
};

export default BookingSuccess;

import React from "react";
import Button from "./Button";
import { useNavigate } from "react-router-dom";

const HeroBannerContent = ({
	HeroContentHeading,
	deskContentPara,
	customfontSize,
	customleading,
	mobContentPara,
}) => {
	const navigate = useNavigate();
	const handleBookOnlineClick = () => {
		navigate("/booking-page");
	};
	return (
		<>
			<div className="flex p-4 flex-col gap-4 ">
				<h1
					className={`inter-medium text-[28px] leading-9  ${customfontSize}  ${customleading} custom-gray10 `}
				>
					{HeroContentHeading}
				</h1>
				<p className="sm:hidden block inter-normal text-start line-height-22 text-sm custom-gray08">
					{deskContentPara}
				</p>
				<p className="sm:block hidden inter-normal text-start line-height-22 text-sm custom-gray08">
					{mobContentPara}
				</p>
			</div>
			<div className="px-4">
				<Button
					onclick={handleBookOnlineClick}
					className="w-full py-2 rounded-md custom-blue-bg text-white custom-blue-border inter-medium desk-input-btn mobile-input-btn text-sm line-height-22"
					callDetails={"Book Your Home Clean"}
				/>
			</div>
		</>
	);
};

export default HeroBannerContent;

import React from "react";

const CleaningServiceSection = ({ serviceListItems }) => {
	return (
		<>
			{serviceListItems.map((serviceListItem, id) => (
				<div
					key={id}
					className="w-full custom-border sm:rounded-lg rounded-2xl flex sm:flex-col-reverse flex-row sm:gap-6 gap-0 md:justify-between p-6 "
				>
					<div className="text-left flex flex-col justify-start items-start  md:w-[416px] sm:gap-4 gap-[33px] ">
						<p className="custom-blue-text inter-600 sm:text-[32px] text-[46px] sm:leading-[39px] leading-[56px]">
							{serviceListItem.heading}
						</p>

						<div className="">
							{serviceListItem.services.map((service, servicesId) => (
								<ul
									className="ml-6 text-sm md:text-base inter-normal custom-gray08 leading-[42px]"
									key={servicesId}
								>
									<li
										className="list-disc custom-gray08 inter-normal text-base inter-normal leading-[42px]"
										key={servicesId}
									>
										{service}
									</li>
								</ul>
							))}
						</div>
					</div>
					<img
						src={serviceListItem.imgUrl}
						alt="serviceImg"
						className="rounded-md h-full"
					/>
				</div>
			))}
		</>
	);
};

export default CleaningServiceSection;

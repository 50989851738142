import React from "react";
import ContactUs from "../components/ContactUsComp/ContactUs";

const ContactUsPage = () => {
	return (
		<div className="relative">
			<ContactUs pageTitle="Contact Us"/>
		</div>
	);
};

export default ContactUsPage;

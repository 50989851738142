import React from "react";

const BookingErrorsToast = ({ errors }) => {
	return (
		<>
			<ul>
				<li>{errors.name}</li>
				<li>{errors.email}</li>
				<li>{errors.phone}</li>
				<li>{errors.address}</li>
				<li>{errors.suburb}</li>
				<li>{errors.state}</li>
				<li>{errors.postcode}</li>
			</ul>
		</>
	);
};

export default BookingErrorsToast;

import React from "react";
import BadgeButton from "./BadgeButton";
import BookingStepsHeading from "./BookingStepsHeading";
import BankTransfer from "../../assets/money-bill.png";
const PaymentMethod = ({ formData, setFormData, stepsnum }) => {
	const handlePaymentMethodSelect = (method) => {
		setFormData((prev) => ({
			...prev,
			step4Data: {
				...prev.step4Data,
				paymentMethod: method,
			},
		}));
	};

	return (
		<div>
			<div className="flex flex-col gap-1">
				<BookingStepsHeading
					stepsnum={stepsnum}
					payMethodPara={"Choose your payment method"}
				/>
			</div>
			<div className="flex flex-col w-full gap-6 step4">
				<BadgeButton
					formData={formData}
					onclick={() => handlePaymentMethodSelect("Credit Card")}
				/>
				<button
					onClick={() => handlePaymentMethodSelect("Bank transfer")}
					className={`flex justify-start items-center gap-6 form-field h-[102px] rounded-xl  ${formData.paymentMethod === "Bank transfer" ? "selected" : ""
						} px-4 py-2 mb-2`}
				>
					<img src={BankTransfer} alt="money-bill" />
					<p className="inter-medium text-base line-height-22 ">
						Bank transfer
					</p>
				</button>
			</div>
		</div>


	)
};

export default PaymentMethod;
